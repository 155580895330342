import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoLink, open, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      setError(false);
    }
  }, [open, videoLink]);

  const handleVideoReady = () => {
    setLoading(false);
  };

  const handleVideoError = () => {
    setLoading(false);
    setError(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent sx={{ p: 1, position: "relative" }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            zIndex: 999999,
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {error && (
          <>
            <Typography align="center" mb={1} variant="h6" color="error">
              Error loading video
            </Typography>
            <Typography align="center" variant="body1" color="error">
              Please use Google Chrome
            </Typography>
          </>
        )}
        {!error && (
          <ReactPlayer
            url={videoLink}
            controls
            playing
            width="100%"
            height="auto"
            onReady={handleVideoReady}
            onError={handleVideoError}
            style={{
              display: loading ? "none" : "block",
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default VideoPlayer;
