import {
  Box,
  CircularProgress,
  Typography,
  Pagination,
  Dialog,
  DialogContent,
  IconButton,
  Snackbar,
  Alert,
  Button,
  DialogActions,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import LogoutIcon from "@mui/icons-material/Logout";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { useRef, useState, useEffect } from "react";
import Recorder from "./Recorder";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";

const ScreenRecorder = () => {
  const mediaRecorderRef = useRef(null);
  const navigate = useNavigate();
  const [capturing, setCapturing] = useState(false);
  const [paused, setPaused] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [error, setError] = useState(null);
  const [time, setTime] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [screenRecorderActive, setScreenRecorderActive] = useState(true);
  const [videosActive, setVideosActive] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [stopMessage, setStopMessage] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [myRecordings, setMyRecordings] = useState([]);
  const [myRecordingsLoading, setMyRecordingsLoading] = useState(false);
  const [noDataText, setNoDataText] = useState("");
  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [recordingToDelete, setRecordingToDelete] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [user, setUser] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(myRecordings.length / itemsPerPage);
  const URL = "https://sr360noc.com/staticrecorder";
  // const URL = "http://localhost:8001";

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const checkAuth = async () => {
      const storedToken = sessionStorage.getItem("token");

      if (storedToken) {
        const resp = await axios.post(
          `${URL}/protected-route`,
          {
            token: storedToken,
          },
          { withCredentials: true }
        );
        if (resp?.data?.success) {
          setUser(resp?.data?.user);
        }
      } else {
        navigate("/");
      }
    };

    checkAuth();
  }, [navigate]);

  let combinedStreamRef = useRef(null);
  let videoElementRef = useRef(null);

  const handleLogout = async () => {
    try {
      await axios.post(`${URL}/logout`, {}, { withCredentials: true });
      sessionStorage.removeItem("token");
      navigate("/");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const handleDelete = async (videoname, stamp) => {
    try {
      const resp = await axios.post(
        `${URL}/delete-video`,
        {
          videoname: videoname,
          stamp: stamp,
          user: user,
        },
        { withCredentials: true }
      );
      if (resp?.data?.success) {
        setMyRecordings((prevRecordings) =>
          prevRecordings.filter(
            (recording) => recording.video_name !== videoname
          )
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    const updatePagination = () => {
      setPage(1); // Reset to first page if the list changes
    };
    updatePagination();
  }, [myRecordings]);

  useEffect(() => {
    const getRecordingData = async () => {
      try {
        const resp = await axios.post(
          `${URL}/get-videos`,
          {
            username: user,
          },
          { withCredentials: true }
        );
        if (resp?.data?.success) {
          if (resp?.data?.data.length === 0) {
            setNoDataText("You don't have any recordings yet");
          } else {
            setMyRecordings(
              resp?.data?.data.sort(
                (a, b) => new Date(b.creation_time) - new Date(a.creation_time)
              )
            );
            setNoDataText("");
          }
        }
        setMyRecordingsLoading(false);
      } catch (error) {}
    };

    if (videosActive && myRecordings?.length === 0) {
      setMyRecordingsLoading(true);

      getRecordingData();
    }
  }, [videosActive]);

  useEffect(() => {
    if (recordedChunks.length > 0) {
      handleDownload();
    }
  }, [recordedChunks]);

  useEffect(() => {
    if (error) {
      const errorTimer = setTimeout(() => {
        setError(null);
      }, 3000);
      return () => clearTimeout(errorTimer);
    }
  }, [error]);

  useEffect(() => {
    let timer;
    if (capturing && !paused) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setVideoDuration(time);
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [capturing, paused]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleShare = (videoLink) => {
    navigator.clipboard.writeText(`${URL}${videoLink}`).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleVideoClick = async (videoLink) => {
    try {
      // Fetch the video data from the URL
      const response = await fetch(`${URL}${videoLink}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", videoLink.split("/").pop());
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("There was an error downloading the video:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentVideo(null);
  };

  const handleStartCaptureClick = async () => {
    try {
      setError(null); // Clear any previous errors
      setCountdown(0);
      setTime(0); // Reset timer

      // Request screen recording permissions
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });

      // Request microphone permissions
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Combine screen and audio streams
      const combinedStream = new MediaStream([
        ...screenStream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);
      combinedStreamRef.current = combinedStream;

      // Create a video element to play the stream and capture a frame
      videoElementRef.current = document.createElement("video");
      videoElementRef.current.srcObject = combinedStream;
      videoElementRef.current.muted = true; // Mute the video element to prevent feedback
      videoElementRef.current.play();

      // Create a MediaRecorder instance
      mediaRecorderRef.current = new MediaRecorder(combinedStream, {
        mimeType: "video/webm",
      });

      // Handle data available event
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        setCapturing(false);
        setStopMessage(true);
      };

      startCountdown();
    } catch (err) {
      setError(
        "Permission denied or no media devices available. Please ensure you allow both screen and microphone access."
      );
    }
  };

  const startCountdown = () => {
    let countdownTime = 5;
    setCountdown(countdownTime);
    const countdownInterval = setInterval(() => {
      countdownTime -= 1;
      setCountdown(countdownTime);
      if (countdownTime <= 0) {
        clearInterval(countdownInterval);
        startRecording();
      }
    }, 1000);
  };

  const captureThumbnail = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoElementRef.current.videoWidth;
    canvas.height = videoElementRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoElementRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      setThumbnail(blob);
    }, "image/png");
  };

  const startRecording = () => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(combinedStreamRef.current, {
      mimeType: "video/webm;codecs=vp9",
    });

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        setRecordedChunks((prev) => [...prev, event.data]);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      setCapturing(false);
      handleDownload();
    };

    mediaRecorderRef.current.start();

    // Capture thumbnail after 5 seconds
    setTimeout(captureThumbnail, 5000);
  };

  const handlePauseCaptureClick = () => {
    if (mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.pause();
      setPaused(true);
    }
  };

  const handleResumeCaptureClick = () => {
    if (mediaRecorderRef.current.state === "paused") {
      mediaRecorderRef.current.resume();
      setPaused(false);
    }
  };

  const handleStopCaptureClick = () => {
    console.log("Stopping capture");
    mediaRecorderRef.current.stop();
    combinedStreamRef.current.getTracks().forEach((track) => track.stop());
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleUpload = async (blob) => {
    try {
      const formData = new FormData();
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/:/g, "-");
      const randomString = generateRandomString(20);
      const fileName = `recording_${randomString}_${formattedDate}.webm`;
      formData.append("file", blob, fileName);
      formData.append("user", user);
      formData.append("duration", time);

      if (thumbnail) {
        const thumbnailFileName = `thumbnail_${randomString}_${formattedDate}.png`;
        formData.append("thumbnail", thumbnail, thumbnailFileName);
      }

      const resp = await axios.post(`${URL}/upload`, formData, {
        withCredentials: true,
      });

      if (resp?.data?.success) {
        setMyRecordings((prevRecordings) => [
          resp?.data?.data,
          ...prevRecordings,
        ]);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleDownload = async () => {
    setUploading(true);
    console.log("Recorded chunks:", recordedChunks);
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      await handleUpload(blob); // Call the upload function
      setRecordedChunks([]); // Clear recorded chunks

      const resp = await axios.post(
        `${URL}/get-videos`,
        {
          username: user,
        },
        { withCredentials: true }
      );
      if (resp?.data?.success) {
        if (resp?.data?.data.length === 0) {
          setNoDataText("You don't have any recordings yet");
        } else {
          setMyRecordings(
            resp?.data?.data.sort(
              (a, b) => new Date(b.creation_time) - new Date(a.creation_time)
            )
          );
          setNoDataText("");
          setUploading(false);
          setScreenRecorderActive(false);
          setVideosActive(true);
        }
      }
    } else {
      console.log("No recorded chunks available for download.");
    }
  };

  // Calculate the start and end indices of the recordings to display based on the current page
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRecordings = myRecordings.slice(startIndex, endIndex);

  return (
    <>
      {!user ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="warning" size="5rem" />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            margin: 0,
            padding: 0,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "20rem" },
              backgroundColor: "#333",
              padding: "1rem",
              color: "white",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box mb={1}>
                <Typography variant="h6">Recorder</Typography>
                <Typography
                  sx={{
                    display: "inline-block",
                    textDecoration: screenRecorderActive
                      ? "underline"
                      : undefined,
                    cursor: screenRecorderActive ? "default" : "pointer",
                    "&:hover": {
                      textDecoration: !screenRecorderActive
                        ? "underline"
                        : undefined,
                    },
                  }}
                  pl={2}
                  variant="body1"
                  onClick={() => {
                    if (!screenRecorderActive) {
                      setScreenRecorderActive(true);
                      setVideosActive(false);
                    }
                  }}
                >
                  Screen Recording
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">My Library</Typography>
                <Typography
                  sx={{
                    display: "inline-block",
                    textDecoration: videosActive ? "underline" : undefined,
                    cursor: videosActive ? "default" : "pointer",
                    "&:hover": {
                      textDecoration: !videosActive ? "underline" : undefined,
                    },
                  }}
                  pl={2}
                  variant="body1"
                  onClick={() => {
                    if (!videosActive) {
                      setScreenRecorderActive(false);
                      setVideosActive(true);
                    }
                  }}
                >
                  Videos
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: "auto" }}>
              <Box
                onClick={handleLogout}
                sx={{ display: "flex", cursor: "pointer" }}
              >
                <LogoutIcon />
                <Typography
                  sx={{
                    ml: 0.5,
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Logout
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              backgroundColor: "#1e1e1e",
              padding: "2rem",
              overflowX: "hidden",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography align="center" variant="h3" color="white">
              {screenRecorderActive ? "Screen Recorder" : "Videos"}
            </Typography>

            {screenRecorderActive && (
              <>
                <Recorder
                  error={error}
                  capturing={capturing}
                  countdown={countdown}
                  handleStopCaptureClick={handleStopCaptureClick}
                  handleResumeCaptureClick={handleResumeCaptureClick}
                  handlePauseCaptureClick={handlePauseCaptureClick}
                  handleStartCaptureClick={handleStartCaptureClick}
                  paused={paused}
                  recordedChunks={recordedChunks}
                  handleDownload={handleDownload}
                  formatTime={formatTime}
                  time={time}
                  uploading={uploading}
                />
              </>
            )}

            {videosActive && (
              <Box
                sx={{
                  marginTop: "2rem",
                  backgroundColor: "#252526",
                  padding: "1rem",
                  borderRadius: "8px",
                  width: "100%",
                  maxWidth: "800px",
                  minHeight: myRecordings.length > 0 ? "715px" : undefined,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6" color="white">
                  Media
                </Typography>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  {myRecordingsLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : myRecordings.length > 0 ? (
                    paginatedRecordings.map((recording, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          alignItems: "center",
                          mb: 3,
                        }}
                      >
                        <Box
                          onClick={() => handleVideoClick(recording.video_link)}
                          sx={{
                            width: "150px",
                            height: "100px",
                            backgroundColor: "#444",
                            marginRight: { xs: 0, sm: "1rem" },
                            marginBottom: { xs: "1rem", sm: 0 },
                            backgroundImage: `url(${URL}${recording.thumbnail_link})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        />
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            onClick={() =>
                              handleVideoClick(recording.video_link)
                            }
                            sx={{
                              "&:hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                              },
                            }}
                            variant="body1"
                            color="white"
                          >
                            {recording.video_name}
                          </Typography>
                          <Typography variant="body2" color="gray">
                            {recording.creation_time}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: { xs: "1rem", sm: 0 },
                          }}
                        >
                          <AccessTimeIcon sx={{ color: "#fff", mr: 0.5 }} />
                          <Typography sx={{ color: "#fff", mr: 2 }}>
                            {formatTime(recording?.duration)}
                          </Typography>
                          <IconButton
                            aria-label="share"
                            onClick={() => handleShare(recording.video_link)}
                          >
                            <ShareIcon sx={{ color: "white" }} />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setRecordingToDelete(recording);
                              setDeleteModalOpen(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: "white" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography
                      sx={{ color: "#fff", textAlign: "center", width: "100%" }}
                    >
                      {noDataText}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "auto",
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChange}
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "white",
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <VideoPlayer
            videoLink={`${URL}${currentVideo}`}
            open={open}
            onClose={handleClose}
          />
          <Dialog
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogContent sx={{ backgroundColor: "#d3d3d3" }}>
              <Typography variant="h5" align="center">
                Are you sure you want to remove this recording?
              </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: "#d3d3d3" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  gap: 2,
                  mb: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => setDeleteModalOpen(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDelete(
                      recordingToDelete.video_name,
                      recordingToDelete.video_stamp
                    );
                    setDeleteModalOpen(false);
                  }}
                  color="secondary"
                >
                  Delete
                </Button>
              </Box>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Link copied to clipboard
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
};

export default ScreenRecorder;
