// src/components/LoginPage.js
import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const URL = "https://sr360noc.com/staticrecorder";
  // const URL = "http://localhost:8001";

  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleLogin(event);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [username, password]);

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${URL}/login`,
        { username, password },
        { withCredentials: true }
      );

      if (response.data.success) {
        const receivedToken = response.data.token;
        if (receivedToken) {
          sessionStorage.setItem("token", receivedToken); // Save the token in sessionStorage
          navigate("/recorder");
        } else {
          console.error("Session token mismatch");
          setSnackbarOpen(true);
        }
      } else {
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <TextField
        label="Username"
        variant="outlined"
        margin="normal"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleLogin}>
        Login
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Incorrect Login! Your User Name or Password is incorrect
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginPage;
