import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Recorder = (props) => {
  return (
    <>
      {props?.uploading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
          {props?.error && (
            <Typography mb={2} color="red">
              {props?.error}
            </Typography>
          )}
          {props?.countdown > 0 ? (
            <Typography variant="h4" color="white">
              Recording starts in {props?.countdown}
            </Typography>
          ) : props?.capturing ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  await props?.handleStopCaptureClick();
                }}
              >
                Stop Capture
              </Button>
              {props?.paused ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props?.handleResumeCaptureClick}
                  sx={{ marginLeft: "1rem" }}
                >
                  Resume Capture
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props?.handlePauseCaptureClick}
                  sx={{ marginLeft: "1rem" }}
                >
                  Pause Capture
                </Button>
              )}
            </>
          ) : (
            <LoadingButton
              loading={props?.uploading}
              variant="contained"
              sx={{
                color: props?.uploading ? "#fff" : undefined,
                "&.MuiLoadingButton-loading": {
                  backgroundColor: "#000", // Assuming you want a black background when loading
                },
              }}
              onClick={props?.handleStartCaptureClick}
            >
              {props?.uploading ? "Uploading Video" : "Start Capture"}
            </LoadingButton>
          )}
        </Box>
      )}

      <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
        <Typography variant="h4" color="white">
          {props?.capturing && props?.countdown === 0
            ? `Recording... ${props?.formatTime(props?.time)}`
            : "Not Recording"}
        </Typography>
      </Box>
    </>
  );
};

export default Recorder;
